import fetchService from './FetchService'
import axiosService from './AxiosService'
import logService from './LogService'

const HttpSelector = Object.freeze({
    UseFetch: 'UseFetch',
    UseAxios: 'UseAxios'
});

const ApiSelector = Object.freeze({
    UseApi: 'UseApi',
    UseApp: 'UseApp'
});

class HttpService {
    constructor() {
        this.httpSelector = HttpSelector.UseFetch;
        this.apiSelector = ApiSelector.UseApi;

        this.apiToken = null;
        this.sessionId = null;
        this.refreshingApiToken = false;

        //client = cubert-landing
        this.hssEdClientId = '39b11001-4269-4b26-a138-db50c2bbdf69';
        this.hssEdClientSecret = '40015df8-380c-456e-abf1-254791cb9509';
        this.hssEdClientPlatform = 'web';

        let metaSiteName = document.querySelector('meta[name=hssedsitename]');
        if (metaSiteName) {
            this.siteName = metaSiteName.getAttribute('content');
            if (this.siteName.startsWith('@')) {
                this.siteName = "nursececlub.com";
                // NURSING = nursececlub.com accelerated-ce.com ohionursepracticeactce.com newyorkinfectioncontrolce.com paact31training.com cnaceu.com oregon-afh-ce.com michigan-implicit-bias-training.com
                // ACCOUNTING = cpelite.com, affordable-new-jersey-law-ethics.com
            }
        }

        let metaSessionId = document.querySelector('meta[name=hssedsessionid]');
        if (metaSessionId) {
            this.sessionId = metaSessionId.getAttribute('content');
            if (this.sessionId.startsWith('@'))
                this.sessionId = null;
        }

        let metaSlot = document.querySelector('meta[name=hssedslot]');
        if (metaSlot) {
            this.slot = metaSlot.getAttribute('content');
        }
        if (!this.slot || this.slot.startsWith('@')) {
            this.slot = 'prod';
        }

        let metaLocal = document.querySelector('meta[name=hssedlocal]');
        if (metaLocal) {
            this.local = metaLocal.getAttribute('content');
        }
        if (this.local) {
            if (this.local.startsWith('@')) {
                this.local = false;
            }
            else {
                this.local = this.local.toLowerCase() === "true";
            }
        } else {
            this.local = false;
        }

        if (this.local) {
            this.apiUrl = "http://localhost:7002";
        }
        else {
            // const hostname = window.location.hostname;
            // const apiHostname = `api.${hostname}`;
            // this.apiAliasUrl = `${window.location.protocol}//${apiHostname}`;

            switch (this.slot) {
                case 'dev':
                    this.apiUrl = "https://hssedapi.azurewebsites.net";
                    //this.apiUrl = "https://api-dev.hssed.com";
                    break;
                case 'staging':
                    this.apiUrl = "https://api-staging.hssed.com";
                    break;
                default:
                    this.apiUrl = "https://hssedapi.azurewebsites.net";
                    break;
            }
        }

        let metaColor = document.querySelector('meta[name=hssedcolor]');
        if (metaColor) {
            this.color = metaColor.getAttribute('content');
            document.documentElement.style.setProperty('--spinner-color', this.color);
        }
        if (!this.color || this.color.startsWith('@')) {
            this.color = null; //DEFAULT COLOR
            document.documentElement.style.setProperty('--spinner-color', "#4AD5C6");
        }

        console.warn(`http-service.siteName = ${this.siteName}`)
        console.warn(`http-service.slot = ${this.slot}`)
        console.warn(`http-service.local = ${this.local}`)
        console.warn(`http-service.apiUrl = ${this.apiUrl}`)
    }

    setApiRoutingCookie() {
        // Set a cookie named `routeBackend` with a value `appServer` that expires in 1 day
        const cookieName = "routeBackend";
        const cookieValue = "appServer";
        const expiration = new Date();
        expiration.setDate(expiration.getDate() + 1); // 1 day expiration

        document.cookie = `${cookieName}=${cookieValue}; path=/`;
    }

    selectApi(selection = null) {

        if (selection != null) {
            this.httpSelector = selection.httpSelector;
            this.apiSelector = selection.apiSelector;

            console.log('httpService.selectApi() httpSelector = ', this.httpSelector);
            console.log('httpService.selectApi() apiSelector = ', this.apiSelector);

            return Promise.resolve(true);
        }
        else {
            logService.clear();
            logService.add('httpService.selectApi() return checkApi()');
            this.startTime = performance.now();

            return this.checkApi()
                .then(success => {
                    if (success) {
                        let elapsedTime = (performance.now() - this.startTime);
                        let reportError = !this.useFetchUseApi;
                        let message = '';
                        message += !this.useFetchUseApi ? 'no-fetch-api' : '';
                        message += !this.useAxiosUseApi ? ', no-axios-api' : '';
                        message += !this.useFetchUseApp ? ', no-fetch-app' : '';
                        message += !this.useAxiosUseApp ? ', no-axios-app' : '';
                        message += !this.useFetchUseApi ? (this.useThirdParty ? '' : ', no-third-party') : '';
                        message += !this.useFetchUseApi ? (this.getVersion ? ', got-version' : ', no-get-version') : '';
                        let noApi = false;

                        if (this.useFetchUseApi) {
                            this.httpSelector = HttpSelector.UseFetch;
                            this.apiSelector = ApiSelector.UseApi;
                        }
                        else if (this.useAxiosUseApi) {
                            this.httpSelector = HttpSelector.UseAxios;
                            this.apiSelector = ApiSelector.UseApi;
                        }
                        else if (this.useFetchUseApp) {
                            this.httpSelector = HttpSelector.UseFetch;
                            this.apiSelector = ApiSelector.UseApp;
                        }
                        else if (this.useAxiosUseApp) {
                            this.httpSelector = HttpSelector.UseAxios;
                            this.apiSelector = ApiSelector.UseApp;
                        }
                        else {
                            noApi = true;
                        }

                        if (this.apiSelector == ApiSelector.UseApp) {
                            this.setApiRoutingCookie();
                        }

                        if (message != '') {
                            logService.add(`httpService.selectApi() ${message}`);

                            console.log(`httpService.selectApi() ${message}`);
                        }

                        logService.add(`httpService.selectApi() httpSelector = ${this.httpSelector}`);
                        logService.add(`httpService.selectApi() apiSelector = ${this.apiSelector}`);

                        console.log('httpService.selectApi() httpSelector = ', this.httpSelector);
                        console.log('httpService.selectApi() apiSelector = ', this.apiSelector);

                        if (reportError) {
                            let errorModel = {
                                status: noApi ? "failed" : "workaround",
                                source: "selectApi",
                                message: message,
                                lineNo: 0,
                                columnNo: 0,
                                stack: "No stack trace",
                                url: window.location.href,
                                referrer: document.referrer,
                                elapsedTime: elapsedTime,
                                log: logService.log
                            };
                            this.reportError(errorModel);
                        }

                        return true;
                    }
                    console.error('httpService.selectApi() failed', logService.log);
                    return false;
                })
                .catch((e) => {
                    console.error('httpService.selectApi() error', logService.log);
                    return false;
                });
        }
    }

    checkApi() {
        //console.log('calling tryUseFetchUseApi...');
        logService.add('httpService.checkApi() return tryUseFetchUseApi()');
        return this.tryUseFetchUseApi()
            .then(success => {
                this.useFetchUseApi = success;
                //console.log('calling tryUseAxiosUseApi...');
                logService.add('httpService.checkApi() return tryUseAxiosUseApi()');
                return this.tryUseAxiosUseApi()
                    .then(success => {
                        this.useAxiosUseApi = success;
                        //console.log('calling tryUseFetchUseApp...');
                        logService.add('httpService.checkApi() return tryUseFetchUseApp()');
                        return this.tryUseFetchUseApp()
                            .then(success => {
                                this.useFetchUseApp = success;
                                //console.log('calling tryUseFetchUseApp...');
                                logService.add('httpService.checkApi() return tryUseAxiosUseApp()');
                                return this.tryUseAxiosUseApp()
                                    .then(success => {
                                        this.useAxiosUseApp = success;
                                        //console.log('calling tryThirdPartyRequest...');
                                        return this.tryThirdPartyRequest()
                                            .then(success => {
                                                this.useThirdParty = success;
                                                //console.log('calling tryGetVersion...');
                                                return this.tryGetVersion()
                                                    .then(success => {
                                                        this.getVersion = success;

                                                        return this.useFetchUseApi || this.useAxiosUseApi || this.useFetchUseApp || this.useAxiosUseApp;
                                                    });
                                            });
                                    });
                            });
                    });
            });
    }

    tryGetVersion() {
        return this.tryGetVersionImpl()
            .then((success) => {
                if (success) {
                    return true;
                }
                else {
                    return false;
                }
            })
            .catch((e) => {
                return false;
            });
    }

    tryGetVersionImpl() {
        if (!this.useFetchUseApi) {
            this.httpSelector = HttpSelector.UseFetch;
            this.apiSelector = ApiSelector.UseApi;

            const url = 'https://api.hssed.com/api/version';

            const headers = {
                'Content-type': 'text/plain'
            };

            let func = this.getHttpFunc('get');
            if (func) {
                return func(url, headers)
                    .then(text => {
                        logService.add(`httpService.tryGetVersion() then() returns ${text}`);
                        return text;
                    })
                    .catch(error => {
                        console.error('http-service.tryGetVersion() caught error  ', error);
                        logService.add(`httpService.tryGetVersion() caught error = ${error}`);
                        throw error;
                    });
            }
            return Promise.reject(new Error('No http func'));
        }
        else {
            return Promise.resolve(true);
        }
    }

    tryThirdPartyRequest() {
        return this.tryThirdPartyRequestImpl()
            .then((success) => {
                if (success) {
                    return true;
                }
                else {
                    return false;
                }
            })
            .catch((e) => {
                return false;
            });
    }

    tryThirdPartyRequestImpl() {
        if (!this.useFetchUseApi) {
            this.httpSelector = HttpSelector.UseFetch;
            this.apiSelector = ApiSelector.UseApi;

            const url = 'https://jsonplaceholder.typicode.com/posts';

            const headers = {
                'Content-type': 'application/json; charset=UTF-8'
            };

            const body = {
                title: 'foo',
                body: 'bar',
                userId: 1,
            };

            let func = this.getHttpFunc('post');
            if (func) {
                return func(url, headers, body)
                    .then(json => {
                        logService.add(`httpService.tryThirdPartyRequest() then() returns ${JSON.stringify(json)}`);
                        return json;
                    })
                    .catch(error => {
                        console.error('http-service.tryThirdPartyRequest() caught error  ', error);
                        logService.add(`httpService.tryThirdPartyRequest() caught error = ${error}`);
                        throw error;
                    });
            }
            return Promise.reject(new Error('No http func'));
        }
        else {
            return Promise.resolve(true);
        }
    }

    tryUseFetchUseApi() {
        this.httpSelector = HttpSelector.UseFetch;
        this.apiSelector = ApiSelector.UseApi;
        return this.getPreBootstrap();
    }

    tryUseAxiosUseApi() {
        this.httpSelector = HttpSelector.UseAxios;
        this.apiSelector = ApiSelector.UseApi;
        return this.getPreBootstrap();
    }

    tryUseFetchUseApp() {
        this.httpSelector = HttpSelector.UseFetch;
        this.apiSelector = ApiSelector.UseApp;
        return this.getPreBootstrap();
    }

    tryUseAxiosUseApp() {
        this.httpSelector = HttpSelector.UseAxios;
        this.apiSelector = ApiSelector.UseApp;
        return this.getPreBootstrap();
    }

    getPreBootstrap() {
        return this.getPreBootstrapImpl()
            .then((success) => {
                if (success) {
                    return true;
                }
                else {
                    return false;
                }
            })
            .catch((e) => {
                return false;
            });
    }

    getPreBootstrapImpl() {
        const url = `api/site/prebootstrap/${httpService.siteName}/cuberto`;
        const body = {
            "useCache": true,
            "useLanding": true,
            "usePaymentOptions": true,
            "useCatalog": true,
            "usePages": true,
            "useHeaderNavs": true,
            "useFooterNavs": true,
            "usePassTypes": true,
            "useCourseBundleTypes": true,
            "useGroupTypes": true,
            "injectApiToken": true,
            "injectImageSets": true,
            "injectColors": true,
            "injectImageUrls": true,
            "injectImageBytes": false
        };

        logService.add(`httpService.getPreBootstrap() return httpService.post() ${url}`);

        return this.post(url, body, false)
            .then(json => {
                if (json && json.succeeded) {
                    logService.add('httpService.getPreBootstrap() SUCCESS');
                    return true;
                }
                else {
                    if (json) {
                        console.error("Cannot get pre bootstrap - json errors", json.errors);

                        logService.add('httpService.getPreBootstrapImpl() json errors');
                        logService.add('httpService.getPreBootstrap() FAILED');
                    }
                    else {
                        console.error("Cannot get pre bootstrap - null json");

                        logService.add('httpService.getPreBootstrapImpl() null json');
                        logService.add('httpService.getPreBootstrap() FAILED');
                    }
                }
                return Promise.reject(new Error("Failed to get pre bootstrap"));
            })
            .catch(error => {
                console.error('httpService.getPreBootstrapImpl() httpService.post() caught error = ', error);

                logService.add(`httpService.getPreBootstrapImpl() httpService.post() caught error = ${error}`);
                logService.add('httpService.getPreBootstrap() FAILED');
                throw error;
            });
    }

    isBot(userAgent) {
        if (!userAgent) return false; // Ensure a valid userAgent is provided

        // Common bot identifiers in user-agent strings
        const botKeywords = [
            "bot",
            "crawl",
            "spider",
            "slurp",
            "bingpreview",
            "mediapartners", // Google AdSense
            "google",        // General Google crawlers
            "yandex",
            "baidu",
            "duckduckbot",
            "facebot",
            "twitterbot",
            "applebot",
            "semrushbot",
            "screaming frog",
            "ahrefsbot",
            "mj12bot",
            "uptimerobot",
            "curl",          // Command-line tool often used for scraping
            "wget",          // Command-line tool
        ];

        // Convert the user-agent to lowercase for case-insensitive matching
        const lowerCaseUserAgent = userAgent.toLowerCase();

        // Check if the user-agent contains any bot keywords
        return botKeywords.some(keyword => lowerCaseUserAgent.includes(keyword));
    }

    getFetchImplementationIdentifier() {
        const userAgent = navigator.userAgent;

        // Modern browsers support userAgentData (more reliable than userAgent)
        const browserInfo = navigator.userAgentData ? navigator.userAgentData.brands : null;

        // Check for the presence of features tied to specific engines
        const isChromiumBased =
            !!window.chrome ||
            (!!browserInfo && browserInfo.some(brand => brand.brand.includes("Chromium")));

        const isFirefox = userAgent.includes("Firefox");
        const isWebKit = userAgent.includes("AppleWebKit") && !isChromiumBased;

        if (isChromiumBased) {
            return "Chromium-based (Blink engine)";
        } else if (isFirefox) {
            return "Gecko-based (Firefox)";
        } else if (isWebKit) {
            return "WebKit-based (Safari)";
        } else if ('fetch' in window) {
            return "Unknown (Fetch API supported)";
        } else {
            return "Fetch API not supported";
        }
    }

    reportError(error) {
        const url = 'api/reporterror';

        if (!error.source) {
            error.source = httpService.siteName;
        }

        if (!error.userAgent) {
            error.userAgent = navigator.userAgent;
        }
        error.isBot = this.isBot(error.userAgent);

        if (!error.fetchImplementation) {
            error.fetchImplementation = this.getFetchImplementationIdentifier();
        }

        if (!error.connection) {
            if ('connection' in navigator) {
                const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
                error.connection = `type: ${connection.type || 'unknown'}, effectiveType: ${connection.effectiveType || 'unknown'}, downlink: ${connection.downlink || 'unknown'}, rtt: ${connection.rtt || 'unknown'}, saveData: ${connection.saveData || false}`;
            } else {
                error.connection = 'Network Information API is not supported';
            }
        }

        console.error('httpService.reportError() = ', JSON.stringify(error, null, 2));

        let func = this.getHttpFunc('postNoResponse');
        if (func) {
            const headers = this.getHeaders(false);

            return func(url, headers, error)
        }
        return Promise.reject(new Error('No http func'));
    }

    getHttpFunc(method) {
        switch (this.httpSelector) {
            case HttpSelector.UseFetch:
                switch (method) {
                    case 'get':
                        return fetchService.get.bind(fetchService);
                    case 'post':
                        return fetchService.post.bind(fetchService);
                    case 'postNoResponse':
                        return fetchService.postNoResponse.bind(fetchService);
                    case 'put':
                        return fetchService.put.bind(fetchService);
                    case 'delete':
                        return fetchService.delete.bind(fetchService);
                }
                return null;
            case HttpSelector.UseAxios:
                switch (method) {
                    case 'get':
                        return axiosService.get.bind(axiosService);
                    case 'post':
                        return axiosService.post.bind(axiosService);
                    case 'postNoResponse':
                        return axiosService.postNoResponse.bind(axiosService);
                    case 'put':
                        return axiosService.put.bind(axiosService);
                    case 'delete':
                        return axiosService.delete.bind(axiosService);
                }
                return null;
        }
        return null;
    }

    getEndpoint(url) {
        if (url.startsWith('http')) {
            return url;
        }

        switch (this.apiSelector) {
            case ApiSelector.UseApi:
                return `${this.apiUrl}/${url}`;
            case ApiSelector.UseApp:
                return url;
        }
        return null;
    }

    getHeaders(useAuth) {
        let headers = {};
        if (useAuth) {
            headers["Authorization"] = `Bearer ${httpService.apiToken}`;
        }
        headers["HssEdSiteName"] = this.siteName;
        headers["HssEdSessionId"] = this.sessionId;
        headers["HssEdClientId"] = this.hssEdClientId;
        headers["HssEdClientSecret"] = this.hssEdClientSecret;
        headers["HssEdClientPlatform"] = this.hssEdClientPlatform;
        headers["Content-Type"] = "application/json";
        return headers;
    }

    setSession(apiToken, sessionId, expiresIn = 3599) {
        this.apiToken = apiToken;
        this.sessionId = sessionId;
        this.apiTokenExpire = (Date.now() / 1000) + expiresIn;

        //console.log(`http-service.setSession: api token expires in ${expiresIn} seconds`);
    }

    refreshApiToken() {
        //console.log('http-service.refreshApiToken...');

        this.refreshingApiToken = true;

        const url = 'api/identity/token';
        const headers = this.getHeaders();
        const body = {};

        return this.post(url, headers, body, false)
            .then(json => {
                this.refreshingApiToken = false;
                if (json && json.succeeded) {
                    //console.log('http-service.refreshApiToken: got new api token', json);
                    this.setSession(json.result.accessToken, this.sessionId, json.result.accessTokenLifetime);
                    return json;
                }
                console.error('http-service.refreshApiToken: cannot get api token', json);
                return json;
            })
            .catch(error => {
                this.refreshingApiToken = false;
                console.error('http-service.refreshApiToken: error in getting new api token...', error);
                return Promise.reject(error);
            });
    }

    verifyApiToken(verify = true) {
        if (verify) {
            if (this.apiToken) {
                let expireInSeconds = this.apiTokenExpire - (Date.now() / 1000);
                console.warn(`http-service.verifyApiToken: expires in ${expireInSeconds}`);
                if (expireInSeconds < 0) {
                    //api token has expired, so refresh
                    return this.refreshApiToken();
                }
                //api token has not expired
                return Promise.resolve({ succeeded: true });
            }
            console.error('http-service.verifyApiToken: missing apiToken');
            //no api token
            return Promise.resolve({ succeeded: false });
        }
        return Promise.resolve({ succeeded: true });
    }

    get(url, verify = true) {
        logService.add('httpService.get() return verifyApiToken()');

        return this.verifyApiToken(verify)
            .then(result => {
                if (result && result.succeeded) {
                    const headers = this.getHeaders(verify);

                    let endpoint = this.getEndpoint(url);
                    if (endpoint) {
                        let func = this.getHttpFunc('get');
                        if (func) {
                            return func(endpoint, headers)
                                .then(response => {
                                    logService.add('httpService.get() then() return');
                                    return response;
                                })
                                .catch(error => {
                                    console.error('http-service.get() caught error  ', error);
                                    logService.add(`httpService.get() caught error = ${error}`);
                                    throw error;
                                });
                        }
                    }
                    logService.add(`httpService.get() no get func`);
                    return Promise.reject(new Error(`httpService.get() no get func`));
                }
                console.error(result);
                logService.add(`httpService.get() verifyApiToken() bad result = ${result}`);
                return Promise.reject(new Error(`httpService.get() verifyApiToken() bad result = ${result}`));
            })
            .catch(error => {
                console.error('http-service.get() verifyApiToken() caught error  ', error);
                logService.add(`httpService.get() verifyApiToken() caught error = ${error}`);
                throw error;
            });
    }

    post(url, body, verify = true) {
        logService.add('httpService.post() return verifyApiToken()');

        return this.verifyApiToken(verify)
            .then(result => {
                if (result && result.succeeded) {
                    const headers = this.getHeaders(verify);

                    logService.add('httpService.post() return fetch()');

                    let endpoint = this.getEndpoint(url);
                    if (endpoint) {
                        let func = this.getHttpFunc('post');
                        if (func) {
                            return func(endpoint, headers, body)
                                .then(response => {
                                    logService.add('httpService.post() then() return');
                                    return response;
                                })
                                .catch(error => {
                                    console.error('http-service.post() caught error  ', error);
                                    logService.add(`httpService.post() caught error = ${error}`);
                                    throw error;
                                });
                        }
                    }
                    logService.add(`httpService.post() no post func`);
                    return Promise.reject(new Error(`httpService.post() no post func`));
                }
                console.error(result);
                logService.add(`httpService.post() verifyApiToken() bad result = ${result}`);
                return Promise.reject(new Error(`httpService.post() verifyApiToken() bad result = ${result}`));
            })
            .catch(error => {
                console.error('http-service.post() verifyApiToken() caught error  ', error);
                logService.add(`httpService.post() verifyApiToken() caught error = ${error}`);
                throw error;
            });
    }

    postNoResponse(url, body, verify = true) {
        return this.verifyApiToken(verify)
            .then(result => {
                if (result && result.succeeded) {
                    const headers = this.getHeaders(verify);

                    let endpoint = this.getEndpoint(url);
                    if (endpoint) {
                        let func = this.getHttpFunc('postNoResponse');
                        if (func) {
                            return func(endpoint, headers, body)
                                .then(response => {
                                    logService.add('httpService.postNoResponse() then() return');
                                    return response;
                                })
                                .catch(error => {
                                    console.error('http-service.postNoResponse() caught error  ', error);
                                    logService.add(`httpService.postNoResponse() caught error = ${error}`);
                                    throw error;
                                });
                        }
                    }
                    logService.add(`httpService.postNoResponse() no postNoResponse func`);
                    return Promise.reject(new Error(`httpService.postNoResponse() no postNoResponse func`));
                }
                console.error(result);
                logService.add(`httpService.postNoResponse() verifyApiToken() bad result = ${result}`);
                return Promise.reject(new Error(`httpService.postNoResponse() verifyApiToken() bad result = ${result}`));
            })
            .catch(error => {
                console.error('http-service.postNoResponse() verifyApiToken() caught error  ', error);
                logService.add(`httpService.postNoResponse() verifyApiToken() caught error = ${error}`);
                throw error;
            });
    }

    put(url, body, verify = true) {
        return this.verifyApiToken(verify)
            .then(result => {
                if (result && result.succeeded) {
                    const headers = this.getHeaders(verify);

                    let endpoint = this.getEndpoint(url);
                    if (endpoint) {
                        let func = this.getHttpFunc('put');
                        if (func) {
                            return func(endpoint, headers, body)
                                .then(response => {
                                    logService.add('httpService.put() then() return');
                                    return response;
                                })
                                .catch(error => {
                                    console.error('http-service.put() caught error  ', error);
                                    logService.add(`httpService.put() caught error = ${error}`);
                                    throw error;
                                });
                        }
                    }
                    logService.add(`httpService.put() no put func`);
                    return Promise.reject(new Error(`httpService.put() no put func`));
                }
                console.error(result);
                logService.add(`httpService.put() verifyApiToken() bad result = ${result}`);
                return Promise.reject(new Error(`httpService.put() verifyApiToken() bad result = ${result}`));
            })
            .catch(error => {
                console.error('http-service.put() verifyApiToken() caught error  ', error);
                logService.add(`httpService.put() verifyApiToken() caught error = ${error}`);
                throw error;
            });
    }

    delete(url, body, verify = true) {
        return this.verifyApiToken(verify)
            .then(result => {
                if (result && result.succeeded) {
                    const headers = this.getHeaders(verify);

                    let endpoint = this.getEndpoint(url);
                    if (endpoint) {
                        let func = this.getHttpFunc('delete');
                        if (func) {
                            return func(endpoint, headers, body)
                                .then(response => {
                                    logService.add('httpService.delete() then() return');
                                    return response;
                                })
                                .catch(error => {
                                    console.error('http-service.delete() caught error  ', error);
                                    logService.add(`httpService.delete() caught error = ${error}`);
                                    throw error;
                                });
                        }
                    }
                    logService.add(`httpService.delete() no delete func`);
                    return Promise.reject(new Error(`httpService.delete() no delete func`));
                }
                console.error(result);
                logService.add(`httpService.delete() verifyApiToken() bad result = ${result}`);
                return Promise.reject(new Error(`httpService.delete() verifyApiToken() bad result = ${result}`));
            })
            .catch(error => {
                console.error('http-service.delete() verifyApiToken() caught error  ', error);
                logService.add(`httpService.delete() verifyApiToken() caught error = ${error}`);
                throw error;
            });
    }
}

const httpService = new HttpService();
export default httpService;

export { HttpSelector, ApiSelector };