class LogService {
    log = [];

    add(message) {
        if (this.log.length > 200) {
            this.log = [];
        }
        this.log.push(message);
    }

    clear() {
        this.log = [];
    }
}

const logService = new LogService();
export default logService;