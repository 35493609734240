import React from 'react'
import './CreateGroup.scss'
import $ from 'jquery'
import 'select2'
import gsap from 'gsap'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import BubbleButton from '../shared/BubbleButton/BubbleButton'
import { ReactComponent as Error } from '../../assets/error.svg'

import bootstrapService from '../../services/BootstrapService'
import formService from '../../services/FormService'
import paymentService from '../../services/PaymentService'

class CompleteGroup extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.form = null;
        this.groupSwitcher = null;
        this.groupSwitch = null;

        // This Binding
        this.clickSwitch = this.clickSwitch.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    // Lifecyle
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupSelects();
        this.setupSwitcher();
    }

    componentDidUpdate() {
        this.setupSelects();
    }

    // Helpers
    setupSwitcher() {
        if (this.groupSwitcher && paymentService.group) {
            const userSelections = this.groupSwitcher.querySelectorAll(".a4-complete-group-form-selection");
            
            if (!paymentService.group.isAdminOnly) {
                userSelections[0].classList.add("-active");
                this.groupSwitch.style.width = userSelections[0].offsetWidth + 5 + "px";
                this.groupSwitch.style.left = userSelections[0].offsetLeft + "px";
            }
            else {
                userSelections[1].classList.add("-active");
                this.groupSwitch.style.width = userSelections[1].offsetWidth + 5 + "px";
                this.groupSwitch.style.left = userSelections[1].offsetLeft + "px";
            }
        }
    }

    setupSelects() {
        $('.a4-select select').select2({
            minimumResultsForSearch: -1,
        });

        $('.a4-complete-group-form-btn-group-user select, .a4-complete-group-form-btn-group-payment select').on("change", (e, shouldIgnore) => {
            if (!shouldIgnore) {
                const selectedSwitch = $(this.groupSwitcher).find(".a4-complete-group-form-selection").filter(`[data-value="${e.currentTarget.value}"]`)[0];

                if (selectedSwitch) {
                    this.setSwitchActive(selectedSwitch);
                    paymentService.group.isAdminOnly = e.currentTarget.value === "admin";
                }
            }
        });
    }

    setSwitchActive(selection) {
        $(selection).siblings().removeClass("-active");
        selection.classList.add("-active");

        gsap.to(this.groupSwitch, {
            width: selection.offsetWidth,
            left: selection.offsetLeft,
            duration: 0.2,
        });
    }

    // Event Handlers
    clickSwitch(e) {
        if (!e.currentTarget.classList.contains("-active")) {
            this.setSwitchActive(e.currentTarget);

            const select = $(e.currentTarget).parent().find("select");
            select.val(e.currentTarget.dataset.value);
            select.trigger("change", true);

            paymentService.group.isAdminOnly = e.currentTarget.dataset.value === "admin";
        }
    }

    submitForm(e) {
        e.preventDefault();

        const isFormValid = formService.validate(this.form);

        const groupName = e.target.groupName.value;
        const numberOfMembers = e.target.numberOfMembers.value;

        const minMembers = paymentService.group.minMembers;
        const maxMembers = paymentService.group.maxMembers;

        if (numberOfMembers !== "" && numberOfMembers < minMembers) {
            formService.showInputError(this.form, "numberOfMembers", `The number must be greater than ${minMembers}`);
            formService.hideLoadingButton(this.form);
            return;
        }
        else if (numberOfMembers !== "" && maxMembers && numberOfMembers > maxMembers) {
            formService.showInputError(this.form, "numberOfMembers", `The number must be less than ${maxMembers}`);
            formService.hideLoadingButton(this.form);
            return;
        }

        if(!isFormValid) return;

        formService.showLoadingButton(this.form);

        paymentService.group.groupName = groupName;
        paymentService.group.numberOfMembers = numberOfMembers;

        paymentService.item.quantity = numberOfMembers;

        this.props.history.push("/buy");
    }

    // Component
    render() {
        // Go Home If No Payment Data
        if (bootstrapService.data && (!paymentService.item || !paymentService.group)) {
            this.props.history.push(`/pricing/group-plans`);
            return null;
        }

        const title = paymentService.item ? `Create ${paymentService.item.title}`: "";
        const groupName = paymentService.group ? paymentService.group.groupName : "";
        const numberOfMembers = paymentService.group ? paymentService.group.numberOfMembers : "";
        const minMembers = paymentService.group ? paymentService.group.minMembers : "";
        const maxMembers = paymentService.group && paymentService.group.maxMembers ? `- ${paymentService.group.maxMembers}` : "+";

        return (
            <>
                <Navbar />
                <div className="a4-view">
                    <div className="a4-layout a4-complete-group">
                        <section className="a4-complete-group-container">
                            <div className="a4-complete-group-content" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                <header className="a4-complete-group-title">
                                    <h1>
                                        {title}
                                    </h1>
                                </header>
                                <form className="a4-complete-group-form" ref={form => this.form = form} onSubmit={this.submitForm}>
                                    <div className="a4-complete-group-form-group">
                                        <div className="a4-complete-group-form-group-switcher-container -user" ref={div => this.groupSwitcher = div}>
                                            <div className="a4-complete-group-form-btn-group a4-complete-group-form-btn-group-user">
                                                <div className="a4-select sm_hidden">
                                                    <select defaultValue="register">
                                                        <option value="member">Create as Group Member</option>
                                                        <option value="admin">Create as Admin Only</option>
                                                    </select>
                                                </div>
                                                <div className="a4-complete-group-form-selection hidden sm_block" type="button" data-value="member" onClick={this.clickSwitch}>
                                                    Create as Group Member
                                                </div>
                                                <div className="a4-complete-group-form-selection hidden sm_block" type="button" data-value="admin" onClick={this.clickSwitch}>
                                                    Create as Admin Only
                                                </div>
                                            </div>
                                            <div className="a4-switcher" ref={div => this.groupSwitch = div}></div>
                                        </div>
                                        <div className="a4-input">
                                            <input name="groupName" required placeholder="Group Name" defaultValue={groupName} />
                                            <label htmlFor="name">Group Name</label>
                                            <span className="a4-input-error">Enter a group name</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <div className="a4-input">
                                            <input name="numberOfMembers" required placeholder={`Number of Members (${minMembers}${maxMembers})`} defaultValue={numberOfMembers} />
                                            <label htmlFor="numberOfMembers">Number of Members</label>
                                            <span className="a4-input-error">Enter the number of members</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                    </div>
                                    <p className="a4-form-error"></p>
                                    <BubbleButton className="a4-form-submit -primary -fixed" type="submit" formNoValidate>
                                        Proceed to Registration
                                    </BubbleButton>
                                    <div className="a4-complete-group-form-terms">
                                        Your account information will be collected on the next page
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default CompleteGroup;