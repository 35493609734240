import * as jwt from 'jsrsasign'

class SessionService {

    //save the user session in local storage so that the member site will see user is logged in
    setLocalUserSession(json) {
        if (json.succeeded) {
            if (json.result) {
                // could be LoginMemberResult (result.member has accessToken, result.Member has member info)
                // could be Order (result has accessToken, result.memberDigest has member info)
                
                let accessTokenObject = null;
                if (json.result.member && json.result.member.accessToken) {
                    accessTokenObject = json.result.member;
                }
                else if (json.result.accessToken) {
                    accessTokenObject = json.result;
                }

                let member = null;
                let memberDigest = null;
                if (json.result.member && json.result.member.accessToken) {
                    member = json.result.member;          
                }
                else if (json.result.memberDigest) {
                    memberDigest = json.result.memberDigest;
                }

                // console.log('accessTokenObject = ', accessTokenObject);
                // console.log('member = ', member);
                // console.log('memberDigest = ', memberDigest);
                
                if (accessTokenObject && (member || memberDigest)) {

                    let session = {
                        accessToken: accessTokenObject.accessToken,
                        refreshToken: accessTokenObject.refreshToken,
                        userSessionId: accessTokenObject.userSessionId
                    };

                    if (member) {
                        session.firstName = member.firstName;
                        session.lastName = member.lastName;
                        session.disableAutoLogin = member.disableAutoLogin;
                        session.requiresEmailConfirm = member.requiresEmailConfirm;
                    }
                    else if (memberDigest) {
                        session.firstName = memberDigest.firstName;
                        session.lastName = memberDigest.lastName;     
                        session.disableAutoLogin = memberDigest.disableAutoLogin;
                        session.requiresEmailConfirm = memberDigest.requiresEmailConfirm;
                    }

                    if (session.accessToken) {
                        // let tokenHeader = jwt.KJUR.jws.JWS.readSafeJSONString(jwt.b64utoutf8(session.accessToken.split(".")[0]));
                        let tokenPayload = jwt.KJUR.jws.JWS.readSafeJSONString(jwt.b64utoutf8(session.accessToken.split(".")[1]));
                        session.accessTokenNotBefore = tokenPayload.nbf;
                        session.accessTokenExpire = tokenPayload.exp;
                        session.accessTokenExpireLocal = (Date.now() / 1000) + (tokenPayload.exp - tokenPayload.nbf);
                        session.accessTokenName = tokenPayload.name;
                        if (Array.isArray(tokenPayload.email)) {
                            if (tokenPayload.email.length > 0) {
                                session.name = tokenPayload.name;
                                session.email = tokenPayload.email[0];
                            }
                        } 
                        else {
                            session.name = tokenPayload.name;
                            session.email = tokenPayload.email;
                        }
                    }

                    let jsonSession = JSON.stringify(session);
                    window.localStorage.setItem('userSession', jsonSession);

                    //create user session cookie
                    if (session.disableAutoLogin || session.requiresEmailConfirm || (session.email && session.email.startsWith('test_'))) {
                        this.eraseUserSessionCookie();
                    }
                    else {
                        this.setUserSessionCookie(session);
                    }
                }
            }
        }
        return json;
    }

    setUserSessionCookie(session, days = 30) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        var cookieValue = encodeURIComponent(`at=${session.accessToken || ''};atl=${session.accessTokenExpire || ''}`);
        document.cookie = '_us=' + cookieValue + expires + "; path=/; SameSite";
    }

    eraseUserSessionCookie() {
        document.cookie = '_us=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

const sessionService = new SessionService();
export default sessionService;