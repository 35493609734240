import httpService from './HttpService'
import sessionService from './SessionService'

export const MemberType = {
    Payment: 0,
    Register: 1,
    TryForFree: 2,
}

class MemberService {

    constructor() {
        this.confirmEmailValidated = false;
        this.completeProfileValidated = false;
    }

    goToMembers(successRedirect) {
        //console.log('gotToMembers redirect = ', successRedirect);

        window.location.replace(successRedirect ? successRedirect : '/members');
    }

    subscribe(subscribeRequest) {
        const url = 'api/member/subscribe';

        return httpService.post(url, subscribeRequest);
    }

    login(loginRequest) {
        const url = 'api/member/login';

        return httpService.post(url, loginRequest)
            .then(json => {
                if (json && json.succeeded) {
                    sessionService.setLocalUserSession(json);
                }
                return json;
            });
    }

    checkEmailInUse(checkEmailRequest) {
        const url = 'api/member/email/inuse';

        return httpService.post(url, checkEmailRequest)
            .then(json => {
                if (json && json.succeeded) {
                    return json.result;
                }
                else {
                    console.log(json.errors);
                }
            })
    }

    register(registerRequest) {
        const url = 'api/member/register';

        registerRequest.requestType = 1;
        registerRequest.confirmAccount = false;
        registerRequest.autoLogin = true;
        registerRequest.origin = window.location.origin;
        registerRequest.timezoneOffset = -(new Date().getTimezoneOffset() / 60);

        return httpService.post(url, registerRequest)
            .then(json => {                
                if (json && json.succeeded) {
                    sessionService.setLocalUserSession(json);
                }
                return json;
            });
    }

    forgotPassword(forgotPasswordRequest) {
        const url = 'api/member/requestresetpassword';

        forgotPasswordRequest.origin = window.location.origin;

        return httpService.post(url, forgotPasswordRequest);
    }

    resetPassword(resetPasswordRequest) {
        const url = 'api/member/setresetpassword';

        return httpService.post(url, resetPasswordRequest)
            .then(json => {
                if (json && json.succeeded) {
                    sessionService.setLocalUserSession(json);
                }
                return json;
            });
    }

    validateConfirmEmailToken(token) {
        const url = 'api/member/confirmemail';
        const body = {
            "confirmEmailToken": token,
            "origin": window.location.origin
        };

        // Reset
        this.confirmEmailValidated = false;

        return httpService.post(url, body)
            .then(json => {
                if (json && json.succeeded && json.result.status === 0) {
                    this.confirmEmailValidated = true;
                }

                return json;
            });
    }

    validateCompleteProfileToken(token) {
        const url = 'api/member/validatecompleteprofile/${token}';
        const body = {};

        // Reset
        this.completeProfileValidated = false;

        return httpService.post(url, body)
            .then(json => {
                if (json && json.succeeded && json.result.status === 0) {
                    this.completeProfileValidated = true;
                }

                return json;
            });
    }

    completeProfile(completeProfileRequest) {
        const url = 'api/member/completeprofile';

        completeProfileRequest.origin = window.location.origin;
        completeProfileRequest.timezoneOffset = -(new Date().getTimezoneOffset() / 60);

        return httpService.post(url, completeProfileRequest)
            .then(json => {
                if (json && json.succeeded) {
                    sessionService.setLocalUserSession(json);
                }
                return json;
            });
    }

    validateMember(validateMemberRequest) {
        const url = 'api/member/validate';

        return httpService.post(url, validateMemberRequest);
    }

    validateJoinGroup(joinGroupRequest) {
        const url = 'api/group/register/verify';
        const body = {
            recaptchaToken: joinGroupRequest.recaptchaToken,
            registerCode: joinGroupRequest.groupCode,
        };

        return httpService.post(url, body);
    }
}

const memberService = new MemberService();
export default memberService;