import httpService from './HttpService'
import recaptchaService from './RecaptchaService'
import logService from './LogService'
// import axios from 'axios'

class BootstrapService {

    constructor() {
        this.data = null;

        this.primaryColor = null;
        this.secondaryColor = null;

        this.defaultPlanType = null;
        this.defaultStateName = null;

        this.callback = null;
    }

    setCallback(callback) {
        this.callback = callback;
    }

    
    getBootstrap() {
        const url = `api/site/bootstrap/${httpService.siteName}/cuberto`;
        const body = {
            "useCache": true,
            "useLanding": true,
            "usePaymentOptions": true,
            "useCatalog": true,
            "usePages": true,
            "useHeaderNavs": true,
            "useFooterNavs": true,
            "usePassTypes": true,
            "useCourseBundleTypes": true,
            "useGroupTypes": true,
            "injectApiToken": true,
            "injectImageSets": true,
            "injectColors": true,
            "injectImageUrls": true,
            "injectImageBytes": false
        };

        logService.clear();
        logService.add('bootstrapService.getBootstrap() return httpService.post()');

        return httpService.post(url, body, false)
            .then(json => {
                if (json && json.succeeded) {
                    // HTTP & Recaptcha
                    httpService.setSession(json.result.apiToken, json.result.sessionId, json.result.apiTokenLifetime);
                    recaptchaService.siteKey = json.result.recaptchaSiteKey;

                    // Data
                    this.data = json.result;
                    
                    // Default Properties
                    this.setDefaultProperties(json);

                    // Single Course Id
                    this.setSingleCourseId(json);

                    // Colors
                    this.setAllColors(json);

                    // Callback
                    if (this.callback) {
                        return this.callback();
                    }
                    return true;
                }
                else {
                    if (json) {
                        console.error("Cannot get bootstrap - json errors", json.errors);
                        logService.add('bootstrapService.getBootstrap() json errors');
                    }
                    else {
                        console.error("Cannot get bootstrap - null json");
                        logService.add('bootstrapService.getBootstrap() null json');
                    }
                }
                return Promise.reject(new Error("Failed to get bootstrap data"));
            })
            .catch(error => {
                console.error('bootstrapService.getBootstrap() httpService.post() caught error = ', error);
                logService.add(`bootstrapService.getBootstrap() httpService.post() caught error = ${error}`);
                throw error;
            });
    }

    setDefaultProperties(json) {
        if (json.result.landing.sections) {
            let plansSection = json.result.landing.sections.find(s => s.name === "plans");
            if (plansSection) {
                let plans = plansSection.plans;
                if (plans) {
                    let defaultPlan = json.result.landing.sections.find(s => s.name === "plans").plans.find(p => p.isDefault === true);
                    this.defaultPlanType = defaultPlan ? defaultPlan.type : null;
                }
            }
        }
        if (json.result.landing.pages) {
            let requirementsPage = json.result.landing.pages.find(p => p.name === "requirements");
            if (requirementsPage) {
                let requirements = requirementsPage.requirements;
                if (requirements) {
                    let defaultState = requirements.find(r => r.isDefault === true);
                    this.defaultStateName = defaultState ? defaultState.authorityName.toLowerCase() : null;
                }
            }
        }
    }

    setSingleCourseId(json) {
        if (json.result.courseCatalog && json.result.courseCatalog.courses && json.result.courseCatalog.courses.length === 1) {
            this.singleCourseId = json.result.courseCatalog.courses[0].id;
        }
    }

    setAllColors(json) {
        if (json.result.site) {
            if (json.result.site.primaryColor) {
                this.primaryColor = json.result.site.primaryColor;
                document.documentElement.style.setProperty('--primary', json.result.site.primaryColor);
                let rgb = this.hexToRgb(json.result.site.primaryColor);
                if (rgb) {
                    document.documentElement.style.setProperty('--primaryR', rgb.r);
                    document.documentElement.style.setProperty('--primaryG', rgb.g);
                    document.documentElement.style.setProperty('--primaryB', rgb.b);
                }
            }
            if (json.result.site.secondaryColor) {
                this.secondaryColor = json.result.site.secondaryColor;
                document.documentElement.style.setProperty('--secondary', json.result.site.secondaryColor);
                let rgb = this.hexToRgb(json.result.site.secondaryColor);
                if (rgb) {
                    document.documentElement.style.setProperty('--secondaryR', rgb.r);
                    document.documentElement.style.setProperty('--secondaryG', rgb.g);
                    document.documentElement.style.setProperty('--secondaryB', rgb.b);
                }
            }
            if (json.result.site.primaryLightColor) {
                document.documentElement.style.setProperty('--primary-light', json.result.site.primaryLightColor);
                let rgb = this.hexToRgb(json.result.site.primaryLightColor);
                if (rgb) {
                    document.documentElement.style.setProperty('--primary-lightR', rgb.r);
                    document.documentElement.style.setProperty('--primary-lightG', rgb.g);
                    document.documentElement.style.setProperty('--primary-lightB', rgb.b);
                }
            }
        }
    }

    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    getCreditsTerm(credits) {
        if (credits === 1) {
            return this.data.site.creditTerm;
        }
        else if (credits > 1) {
            return this.data.site.creditsTerm;
        }
    }

    getCETerm() {
        if (this.data.site.ceTerm) {
            return this.data.site.ceTerm;
        }
        else {
            return "CE";
        }
    }

    getCategoryTerm() {
        if (this.data.site.getCategoryTerm) {
            return this.data.site.getCategoryTerm
        }
        else {
            return "Category"
        }
    }

    getShowAddress() {
        if (this.data.site) {
            return this.data.site.showAddress
        }
        else {
            return true
        }
    }

    getShowReleaseDate() {
        return this.data.site ? (this.data.site.showReleaseDate === false ? false : true) : true;
    }

    getPaymentOptions() {
        return this.data.paymentOptions;
    }
}

const bootstrapService = new BootstrapService();
export default bootstrapService;