import React from 'react'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Footer from '../shared/Footer/Footer'
import FullNameInput from '../shared/FullNameInput/FullNameInput'
import BubbleButton from '../shared/BubbleButton/BubbleButton'
import { ReactComponent as Error } from '../../assets/error.svg'

import formService from '../../services/FormService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'
import { MemberType } from '../../services/MemberService'

class Register extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.form = null;

        // State
        this.state = {
            useFullName: true,
        }

        // This Binding
        this.ignoreFullName = this.ignoreFullName.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // Event Handlers
    ignoreFullName() {
        this.setState({ useFullName: false });
    }

    submitForm(e) {
        e.preventDefault();

        const isFormValid = formService.validate(this.form);

        const email = e.target.email.value.trim();
        const password = e.target.password.value;
        const confirm = e.target.confirm.value;

        let firstName = "";
        let middleName = "";
        let lastName = "";

        if (this.state.useFullName) {
            firstName = e.target.fullName.value.split(" ")[0];
            lastName = e.target.fullName.value.split(" ")[1];
        }
        else {
            firstName = e.target.firstName.value;
            middleName = e.target.middleName.value;
            lastName = e.target.lastName.value;
        }

        if (password !== confirm) {
            formService.showInputError(this.form, "confirm", "This password does not match");
            formService.hideLoadingButton(this.form);
            return;
        }

        if (!isFormValid) return;

        formService.showLoadingButton(this.form);

        let memberRequest = { email, password, firstName, middleName, lastName };

        // Check if Joining Group
        if (this.props.match.params.group) {
            memberRequest.groupRegisterCode = this.props.match.params.group;
        }

        recaptchaService.getToken("register")
            .then(token => {
                memberRequest.recaptchaToken = token;
                memberRequest.memberType = MemberType.Register;

                memberService.checkEmailInUse(memberRequest)
                    .then((isInUse) => {
                        if (isInUse) {
                            formService.hideAllErrors(this.form);
                            formService.hideLoadingButton(this.form);
                            formService.showInputError(this.form, "email", "This email address is already taken");
                        }
                        else {
                            memberService.register(memberRequest)
                                .then(json => {
                                    if (json.succeeded) {
                                        memberService.goToMembers();
                                    }
                                    else {
                                        formService.hideAllErrors(this.form);
                                        formService.hideLoadingButton(this.form);
                                        formService.showFormError(this.form, json.errors[0]);
                                    }
                                });
                        }
                    })
            });
    }

    // Component
    render() {
        return (
            <>
                <Navbar activeLink="register" />
                <div className="a4-view">
                    <main className="a4-layout a4-auth -register">
                        <div className="a4-auth-container -register">
                            <section className="a4-auth-item-container">
                                <div className="a4-auth-item-shadow"></div>
                                <div className="a4-auth-item-content" data-aos={isMobileOnly ? undefined : "fade-up"}>
                                    <h1 className="a4-auth-item-title"><span className="text-primary">Sign</span> Up</h1>
                                    <form className="a4-auth-form" ref={form => this.form = form} onSubmit={this.submitForm}>
                                        <FullNameInput ignoreFullName={this.ignoreFullName} />
                                        <div className="a4-input">
                                            <input name="email" required placeholder="E-mail address" />
                                            <label htmlFor="email">E-mail address</label>
                                            <span className="a4-input-error">Enter a valid e-mail address</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <div className="a4-input">
                                            <input name="password" placeholder="Password" required type="password" />
                                            <label htmlFor="password">Password</label>
                                            <span className="a4-input-error">Enter a valid password</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <div className="a4-input">
                                            <input name="confirm" placeholder="Confirm Password" required type="password" />
                                            <label htmlFor="password">Confirm Password</label>
                                            <span className="a4-input-error">Enter a valid password</span>
                                            <svg className="a4-svgsprite -error text-secondary">
                                                <Error />
                                            </svg>
                                        </div>
                                        <p className="a4-form-error"></p>
                                        <section className="a4-auth-form-control -join">
                                            <BubbleButton className="a4-form-submit -primary" type="submit" formNoValidate>
                                                <b>Create Account</b>
                                            </BubbleButton>
                                        </section>
                                    </form>
                                </div>
                            </section>
                            <aside className="a4-auth-additional" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay='100'>
                                <span>
                                    Already have an account? &nbsp;
                                    <Link to="/login" className="text-primary">Login</Link>
                                </span>
                            </aside>
                        </div>
                        <Footer />
                    </main>
                </div>
            </>
        )
    }
}

export default Register;