import logService from './LogService'
import axios from 'axios'

class FetchService {
    constructor() {
    }

    handleError(error) {
        if (error.response) {
            // The request was made, and the server responded with a status code
            // that falls outside the range of 2xx
            logService.add('axiosService responded with an error');
            logService.add(`axiosService status = ${error.response.status}`);

            console.error('Server responded with an error:');
            console.error('Status:', error.response.status);
            console.error('Headers:', error.response.headers);
            console.error('Data:', error.response.data);
        } else if (error.request) {
            // The request was made, but no response was received
            logService.add('axiosService no response');
            logService.add(`axiosService code = ${error.code}`);
            logService.add(`axiosService message = ${error.message}`);

            console.error('No response received from server:', error);
            console.error('Error code:', error.code);
            console.error('Error message:', error.message);
            console.error(error.request);
        } else {
            // Something else happened while setting up the request
            logService.add('axiosService error during request setup');
            logService.add(`axiosService code = ${error.code}`);
            logService.add(`axiosService message = ${error.message}`);

            console.error('Error during request setup:', error.message);
        }

        // Optionally handle specific errors (e.g., timeout, network issues)
        if (error.code === 'ECONNABORTED') {
            logService.add('axiosService Request timeout occurred');

            console.error('Request timeout occurred');
        } else if (navigator.onLine === false) {
            logService.add('axiosService No internet connection');

            console.error('No internet connection');
        }
    }

    get(url, headers) {
        headers["UsingAxios"] = "true";
        const requestOptions = {
            headers: headers,
            timeout: 30000
        }

        logService.add('axiosService.get() return fetch()');

        return axios.get(url, requestOptions)
            .then(response => {
                logService.add('axiosService.get() get().then() return');
                return response.data;
            })
            .catch(error => {
                console.error('axiosService.get() get() caught error  ', error);
                logService.add(`axiosService.get() get() caught error = ${error}`);
                this.handleError(error);
                throw error;
            });
    }

    post(url, headers, body) {
        headers["UsingAxios"] = "true";
        const requestOptions = {
            headers: headers,
            timeout: 30000
        }

        logService.add('axiosService.post() return fetch()');

        return axios.post(url, body, requestOptions)
            .then(response => {
                logService.add('axios.post() success');
                return response.data;
            })
            .catch(error => {
                // Handle different types of errors
                console.error('axiosService.post() post() caught error  ', error);
                logService.add(`axiosService.post() post() caught error = ${error}`);
                this.handleError(error);
                throw error;
            });
    }

    postNoResponse(url, headers, body) {
        headers["UsingAxios"] = "true";
        const requestOptions = {
            headers: headers,
            timeout: 30000
        }

        return axios.post(url, body, requestOptions)
            .then(response => {
                logService.add('axios.postNoResponse() success');
                return response.data;
            })
            .catch(error => {
                console.error('axiosService.postNoResponse() post() caught error  ', error);
                logService.add(`axiosService.postNoResponse() post() caught error = ${error}`);
                this.handleError(error);
                throw error;
            });
    }

    put(url, headers, body) {
        headers["UsingAxios"] = "true";
        const requestOptions = {
            headers: headers,
            timeout: 30000
        }

        return axios.put(url, body, requestOptions)
            .then(response => {
                logService.add('axios.put() success');
                return response.data;
            })
            .catch(error => {
                console.error('axiosService.put() put() caught error  ', error);
                logService.add(`axiosService.put() put() caught error = ${error}`);
                this.handleError(error);
                throw error;
            });
    }

    delete(url, headers, body) {
        headers["UsingAxios"] = "true";
        const requestOptions = {
            headers: headers,
            timeout: 30000
        }

        return axios.delete(url, body, requestOptions)
            .then(response => {
                logService.add('axios.delete() success');
                return response.data;
            })
            .catch(error => {
                console.error('axiosService.delete() delete() caught error  ', error);
                logService.add(`axiosService.delete() delete() caught error = ${error}`);
                this.handleError(error);
                throw error;
            });
    }
}

const fetchService = new FetchService();
export default fetchService;