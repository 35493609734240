import httpService from './HttpService';

class FaqService {
    
    askQuestion(faqRequest) {
        const url = 'api/faqs/ask';

        return httpService.post(url, faqRequest);
    }
}

const faqService = new FaqService();
export default faqService;