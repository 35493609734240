import logService from './LogService'

class FetchService {
    constructor() {
    }

    getResponse(response) {
        try {
            if (!response) {
                console.error("http-service.getResponse()) Response is null or undefined");
                logService.add("fetchService.getResponse() Response is null or undefined");
                return Promise.reject(new Error("Response is null or undefined"));
            }

            if (response.ok) {
                const contentType = response.headers.get('Content-Type');
                logService.add(`fetchService.getResponse() response content-type ${contentType}`);

                if (contentType.includes('text/plain')) {
                    logService.add("fetchService.getResponse() return response.text()");
                    return response.text();
                } else if (contentType.includes('application/json')) {
                    logService.add("fetchService.getResponse() return response.json()");
                    return response.json();
                } else {
                    throw new Error('Unsupported content type');
                }
            }

            logService.add("fetchService.getResponse() response not ok");
            logService.add(`fetchService.getResponse() response.status =  ${response.status}`);
            logService.add(`fetchService.getResponse() response.statusText =  ${response.statusText}`);
            logService.add(`fetchService.getResponse() response.url =  ${response.url}`);

            const headersArray = [...response.headers.entries()]; // Convert headers to an array
            const headersString = headersArray
                .map(([key, value]) => `${key}: ${value}`) // Format each header as "key: value"
                .join(', '); // Join them with newlines
            logService.add(`fetchService.getResponse() response.headers =  ${headersString}`);

            // Handle HTTP error responses
            const errorDetails = {
                status: response.status,
                statusText: response.statusText,
                url: response.url,
                headers: [...response.headers.entries()] // Include headers if needed
            };

            // Try parsing the response body for more error details
            return response.json()
                .then((body) => {
                    errorDetails.body = body;
                    console.error("http-service.getResponse() response body = ", body);
                    return Promise.reject(errorDetails);
                })
                .catch(() => {
                    // Reject with error details if the body is not JSON
                    return Promise.reject(errorDetails);
                });
        } catch (error) {
            console.error("http-service.getResponse() Unexpected error = ", error);
            logService.add(`fetchService.getResponse() Unexpected error = ${error}`);
            return Promise.reject(error); // Handle unexpected errors
        }
    }

    get(url, headers) {
        const requestOptions = {
            method: "GET",
            headers: headers,
        }

        logService.add(`fetchService.get() return fetch() ${url}`);

        return fetch(url, requestOptions)
            .then(response => {
                logService.add('fetchService.get() fetch().then() return');
                return this.getResponse(response);
            })
            .catch(error => {
                console.error('fetchService.get() fetch() caught error, url = ', url);
                console.error('fetchService.get() fetch() caught error = ', error);
                logService.add(`fetchService.get() fetch() caught error = ${error}`);
                throw error;
            });
    }

    post(url, headers, body) {
        const requestOptions = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body)
        };

        logService.add(`fetchService.post() return fetch() ${url}`);

        return fetch(url, requestOptions)
            .then(response => {
                logService.add('fetchService.post() fetch().then() return');
                return this.getResponse(response);
            })
            .catch(error => {
                console.error('fetchService.post() fetch() caught error, url = ', url);
                console.error('fetchService.post() fetch() caught error = ', error);
                logService.add(`fetchService.post() fetch() caught error = ${error}`);
                throw error;
            });
    }

    postNoResponse(url, headers, body) {
        const requestOptions = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body)
        }

        logService.add(`fetchService.postNoResponse() return fetch() ${url}`);

        return fetch(url, requestOptions)
            .then(response => {
                logService.add('fetchService.post() postNoResponse().then() return');
                return response;
            })
            .catch(error => {
                console.error('fetchService.postNoResponse() fetch() caught error, url = ', url);
                console.error('fetchService.postNoResponse() fetch() caught error = ', error);
                logService.add(`fetchService.postNoResponse() fetch() caught error = ${error}`);
                throw error;
            });
    }

    put(url, headers, body) {
        const requestOptions = {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body)
        }

        logService.add(`fetchService.put() return fetch() ${url}`);

        return fetch(url, requestOptions)
            .then(response => {
                logService.add('fetchService.put() fetch().then() return');
                return this.getResponse(response);
            })
            .catch(error => {
                console.error('fetchService.put() fetch() caught error, url = ', url);
                console.error('fetchService.put() fetch() caught error = ', error);
                logService.add(`fetchService.put() fetch() caught error = ${error}`);
                throw error;
            });
    }

    delete(url, headers, body) {
        const requestOptions = {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(body)
        }

        logService.add(`fetchService.delete() return fetch() ${url}`);

        return fetch(url, requestOptions)
            .then(response => {
                logService.add('fetchService.delete() fetch().then() return');
                return this.getResponse(response);
            })
            .catch(error => {
                console.error('fetchService.delete() fetch() caught error, url = ', url);
                console.error('fetchService.delete() fetch() caught error = ', error);
                logService.add(`fetchService.delete() fetch() caught error = ${error}`);
                throw error;
            });
    }
}

const fetchService = new FetchService();
export default fetchService;